$font-color: #d0d0d0;
$font-color-light: white;
$background-color: #242424;
$background-color-transparent: rgb(36 36 36 / 85%);
$error-color: #ff5a5a;
$error-color-hover: #da3b3b;
$post-block-bg-color: #2f2f2f;
$description-color: #585858;
$light-white-transparent: rgba(256,256,256, 0.15);
$check-bg-off: #1a1a1a;
$check-bg-on: #43e493;

$trippy-pixels-color: #b49db4;