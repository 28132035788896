#home {
    margin-top: -128px;
    min-height: 100vh;

    #hero {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        min-height: 100vh;
        padding-top: 158px;
        margin-top: 128px;
        padding-left: 0;
        padding-right: 0;
        .btn-secondary {
            z-index: 2;
            position: relative;
        }
        .section-title {
            color: $font-color-light;
            position: relative;
            z-index: 1;
            margin-bottom: 0;
            font-size: 100px;
        }
        .section-description {
            max-width: calc(50vw - 4rem);
            min-width: 320px;
            width: 100%;
            display: block;
            position: relative;
            z-index: 1;
            margin: 2rem 0;
            letter-spacing: 1px;
        }
        .section-image {
            max-width: calc(80vw - 4rem);
            min-height: 100vh;
            object-fit: cover;
            display: block;
            position: absolute;
            top: 0;
            right: -15vw;
            z-index: 0;
        }
        .arrow-down {
            width: 60px;
            margin-top: 5rem;
            cursor: pointer !important;
            &:hover {
                opacity: .5;
            }
        }
        .btn-secondary {
            font-weight: normal;
        }
    }
    #principles {
        margin-bottom: 2rem;
    }
    .pre-title {
        margin-top: 120px;
        margin-bottom: 0;
        & + .section-widget {
            margin-top: 4rem;
        }
    }
}
@media screen and (max-width: 767px) {
    #home {
        #principles,
        #hero {
            .section-title {
                font-size: 32px !important;
                line-height: 1.2 !important;
                margin-top: 95px;
            }
        }
        .section-info {
            padding: 15px !important;
        }
        .ability {
            > img {
                width: 40px;
                height: 40px;
            }
            .ability-title {
                font-size: 18px;
            }
        }
        .pre-title {
            margin-top: 80px;
            margin-left: 0 !important;
        }
        .section-widget {
            margin-top: 2rem !important;
            &#principles {
                .section-title {
                    margin-top: 2rem;
                }
            }
        }
    }
}
@media screen and (max-width: 976px) {
    #home {
        padding: 15px;
        section:not(#hero) {
            .section-title {
                top: -45px;
                position: relative;
            }
            .section-info {
                padding: 48px;
            }
        }
        #principles,
        #hero {
            .section-title {
                opacity: 1!important;
                transform: initial !important;
                font-size: 56px;
                line-height: 1.2;
            }
        }
        #hero {
            justify-content: center;
            padding: 0;
            margin-top: 6rem;
            .section-image {
                max-width: 100vw;
                top: 17px;
                right: auto;
                opacity: 0.2 !important;
                left: -15px;
            }
            .section-description {
                min-width: 100%;
                max-width: 100%;
                opacity: 1!important;
                transform: initial !important;
            }
            .arrow-down {
                display: none;
            }
        }
        .abilities-box {
            margin-top: 0;
        }
        .pre-title {
            margin-top: 80px;
            margin-left: 2rem;
        }
        #principles {
            margin-bottom: 0;
            padding-bottom: 0;
            .ability:last-child {
                margin-bottom: 0;
            }
        }
    }
}
@media screen and (min-width: 1366px) and (max-width: 1600px) {
    #home {
        #hero {
            .section-title {
                font-size: 56px;
            }
        }
    }
}

@media screen and (min-width: 976px) and (max-width: 1366px) {
    #home {
        #hero {
            .section-title {
                font-size: 56px;
            }
        }
        .section-image {
            transform: none !important;
        }
        .abilities-box {
            .ability {
                flex-direction: initial;
            }
        }
        .section-widget {
            min-height: initial;
            > .section-image {
                top: 0;
            }
        }
        .pre-title {
            margin-top: 80px;
            margin-left: 2rem;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #home {
        #hero {
            justify-content: flex-start;
        }
    }
}