#contact {
    .section-body {
        display: flex;
        margin-bottom: 4rem;
        margin-top: 3rem;
        flex-wrap: wrap;
        padding: 2rem 0;
        .err-msg {
            width: calc(100% - 5rem);
        }
        > div {
            width: 50%;
            h2 {
                color: $font-color-light;
                font-size: 32px;
                line-height: 1.2;
                margin-top: 0;
            }
            &.info-box {
                width: 100%;
                margin-top: 4rem;
                padding-top: 2rem;
                border-top: 1px solid $font-color;
                display: flex;
                flex-wrap: wrap;
                > div {
                    width: 50%;
                }
                h2 {
                    margin-top: 0;
                }
                ul {
                    list-style: none;
                    padding: 0;
                    li {
                        display: flex;
                        align-items: center;
                        margin-bottom: 1rem;
                        img {
                            width: 25px;
                            height: 25px;
                            margin-right: .5rem;
                        }
                    }
                }
            }
            &.place-box {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }
            .map {
                width: 100%;
                height: calc(85% - 4rem);
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $font-color;
                margin: auto 0;
                & + .flex-box {
                    margin-top: 1rem;
                    justify-content: flex-start;
                    cursor: pointer;
                    transition: opacity 0.5s ease;
                    width: fit-content;
                    margin-bottom: 0;
                    &:hover {
                        opacity: 0.5;
                    }
                    p {
                        margin: 0 0 0 .5rem;
                    }
                }
            }
            a {
                border-bottom: 1px solid $font-color;
            }
            .contact-field {
                padding: 0 5rem 0 0;
                margin-bottom: 24px;
                p {
                    margin-bottom: .5rem;
                }
                input, textarea {
                    width: 100%;
                }
                &.flex-box {
                    align-items: flex-start;
                    .checkbox {
                        margin-top: 7px;
                    }
                    > p {
                        margin: -2px 0 0 1rem;
                    }
                }
            }
        }
        .open-hours {
            tbody {
                td {
                    padding: .5rem 2rem 0 0;
                    min-width: 165px;
                    &:last-child {
                        padding-right: 0;
                    }
                }
                .days {
                    color: white;
                    font-weight: bold;
                    min-width: 255px;
                    & + .flex-box {
                        margin-bottom: 0;
                    }
                }
                .hours {
                    margin-left: 4rem;
                }
                p {
                    margin: 0;
                }
            }
        }
    }
    & + .sub-footer {
        display: none;
    }
    .msg {
        position: fixed;
        width: 100vw;
        left: 0;
        bottom: -100px;
        padding: 1rem;
        background-color: $check-bg-on;
        color: $background-color;
        text-align: center;
        z-index: 20;
        margin-bottom: 0;
        transition: all 0.5s ease;
        &.open {
            bottom: 0;
        }
    }
}
@media screen and (max-width: 768px) {
    #contact .section-body {
        .open-hours, .contact {
            width: 100% !important;
            .hours {
                width: auto;
                min-width: 105px !important;
            }
            tr > td.days {
                padding-right: 30px !important;
            }
        }
    }
}
@media screen and (max-width: 1130px) {
    #contact {
        .section-body {
            flex-direction: column;
            margin-bottom: 2rem;
            margin-top: 0;
            margin-left: 0;
            padding-top: 0;
            > div {
                width: 100%;
                h2 {
                    font-size: 20px;
                    margin-top: 2rem;
                }
                .err-msg {
                    width: 100%;
                }
                .contact-field {
                    padding: 0;
                    p > a {
                        font-size: 14px;
                    }
                }
                .map {
                    min-height: 200px;
                    > div {
                        min-height: 200px;
                    }
                }
                &.info-box {
                    margin-top: 2rem;
                    padding-top: 2rem;
                }
            }
            .open-hours {
                min-width: 300px;
                margin-top: 2rem;
                table {
                   border-collapse: collapse;
                }
                td {
                    padding: .5rem .5rem 1rem 0 !important;
                    min-width: 135px !important;
                    border-bottom: 1px solid $description-color;

                    .hours {
                        padding-left: 1rem !important;
                    }
                    &.days {
                        min-width: 100px;
                        padding-right: 0.5rem !important;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #contact {
        .section-body {
            flex-direction: initial;
            flex-wrap: wrap;
            justify-content: space-between;
            > div {
                width: calc(50% - 30px);
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
    #contact {
        .section-body {
            > div {
                h2 {
                    line-height: 1.2;
                }
            }
            .open-hours {
                min-width: 300px;
                margin-top: 0;
                td {
                    padding: .5rem;
                }
            }
        }
    }
}