input {
    background-color: transparent !important;
    color: $font-color;
    border: 1px solid $font-color;
    padding: .5rem 1rem;
}
.has-error > input,
.has-error > textarea {
    border-color: $error-color;
}
.location-field {
    transition: all 0.5s ease;
    margin-top: 2rem;
    border: 1px solid $font-color;
    padding: 32px 32px 32px 24px;
    border-radius: calc(104px / 2);
    height: 104px;
    width: calc(50% - 1rem);
    min-width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:not(.active):hover {
        background-color: rgba(255, 255, 255, 0.309);
        svg path,
        input,
        span {
            color: $font-color-light !important;
        }
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
    &:not(:first-child) {
        margin-left: 1rem;
    }
    svg {
        width: 40px;
        height: 40px;
        transition: all 0.5s ease;

        &.collapsed-icon {
            margin-left: auto;
            padding: 8px;
        }
        &.active {
            transform: rotate(90deg);
        }
    }
    input,
    span {
        border: 0;
        font-weight: bold;
        font-size: 24px;
        transition: all 0.5s ease;
        &:not(input) {
            margin-right: 2rem;
        }

        &:focus {
            border: 0;
            outline: none;
        }
    }
    &.active {
        background-color: white;
        svg path,
        input,
        span {
            color: $background-color;
        }
    }
}
@media screen and (max-width: 1130px) {
    .location-field {
        padding: 10px 32px 10px 24px;
        height: 50px;
        svg {
            width: 32px;
            height: 32px;
        }
        span, input {
            font-size: 16px;
        }
    }
}