.dropdown {
    max-width: 300px;
    position: relative;
    ul {
        position: absolute;
        list-style: none;
        display: block;
        padding: 0;
        width: 100%;
        margin-top: 0;
        max-height: 0;
        height: fit-content;
        overflow: hidden;
        background-color: $background-color;
        transition: all 0.5s ease;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        z-index: 2;
        &.open {
            border-left: 1px solid $font-color;
            border-right: 1px solid $font-color;
            border-bottom: 1px solid $font-color;
            padding: 15px;
            max-height: 300px;
            li {
                opacity: 1;
            }
        }
        li {
            display: flex;
            align-items: center;
            opacity: 0;
            transition: all 0.5s ease;
            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
                > .check {
                    background-color: $font-color;
                    pointer-events: none;
                }
            }
            .check {
                margin-right: 0.5rem;
                display: block;
                width: 20px;
                height: 20px;
                background-color: transparent;
                border: 1px solid $font-color;
                transition: all 0.5s ease;
                &.checked {
                    background-color: $font-color;
                }
            }
        }
    }
    .btn-drop {
        border: 1px solid $font-color;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        margin-top: 1rem;
        transition: all 0.5s ease;
        width: 100%;
        padding: 0.5rem 1rem;
        &.open > svg {
            transform: rotate(-180deg);
        }
        p {
            margin: 0;
        }
        > svg {
            transition: all 0.5s ease;
            margin-left: auto;
        }
    }
}