#gallery {
  min-height: 100vh;
  .feeds-box {
    display: flex;
    margin-top: 4rem;
    margin-bottom: 4rem;
    overflow-x: auto;
    padding-bottom: 1rem;
    .gallery-location {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: calc(100vw / 2 - 3rem);
      > h4 {
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 32px;
        color: $font-color-light;
        > svg {
          margin-right: 24px;
          font-size: 48px;
        }
      }
      .btn-primary {
        margin-top: 32px;
      }
      .images-box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;

        > .feed {
          width: calc(100% / 3.5 - 2.7rem);
          aspect-ratio: 1/1;
          margin: 1px;
          display: block;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          .insta-logo {
            position: absolute;
            opacity: 0;
            top: 30%;
            left: 50%;
            color: $font-color-light;
            transform: translate(-50%, -50%);
            width: 60px;
            height: 60px;
            transition: all 0.5s ease !important;
            pointer-events: none;
          }
          &:hover {
            .insta-logo {
              opacity: 1;
              top: 50%;
            }
          }
          img,
          video {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            transition: all 0.3s ease;
            &:hover {
              opacity: 0.3;
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1130px) {
  #gallery {
    .feeds-box > .gallery-location {
      min-width: calc(100vw - 15px);
    }
    .gallery-container {
      flex-direction: column;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  #gallery {
    .feeds-box {
      justify-content: center;
      > .feed {
        width: calc(50% - 1.5rem);
        height: calc(50vw - 1.5rem);
      }
    }
  }
}
