#settings {
    min-height: 100vh;
    .section-body {
        display: flex;
        justify-content: space-between;
        margin-top: 4rem;
        flex-wrap: wrap;
        & > div {
            width: calc(100% / 2 - 1rem);
            padding: 32px;
            border: 1px solid $font-color;
            margin-top: 2rem;
            p {
                margin-bottom: 0;
                color: $font-color;
                font-size: 16px;
            }
            .flex-box > div > span,
            .flex-box > span {
                color: $font-color-light;
                word-break: break-all;
                font-size: 18px;
            }
            input {
                width: 100%;
            }
            & > h2 {
                min-height: 46px;
                font-size: 32px;
                margin-top: 0;
            }
        }
        .owner {
            .flex-box {
                justify-content: flex-start;
                flex-direction: column;
                margin-bottom: 0;
                > input {
                    margin-bottom: 1rem;
                }
                > span {
                    color: $font-color-light;
                    margin-right: 1rem;
                }
            }
        }
        .social-media-box {
            .flex-column > div {
                border-bottom: 1px solid $font-color;
                padding-bottom: 1rem;
                & > .flex-box {
                    margin-bottom: 0;
                }
                input {
                    width: 100%;
                }
            }
            p {
                text-transform: capitalize;
            }
        }
        .open-times-box {
            width: 100%;
        }
        .open-hours-container {
            display: flex;
            .open-card {
                width: calc(100% / 3 - 1rem);
                padding: 1rem;
                border: 1px solid $font-color;
                > div {
                    margin-top: 1rem;
                    > label:not(:last-child) {
                        margin-bottom: 1rem;
                        display: block;
                    }
                }
                h4 {
                    margin-top: 0;
                }
                .bold {
                    font-weight: bold;
                }
                p {
                    margin: 0;
                }
                .days {
                    opacity: 0.5;
                }
                &:not(:first-child) {
                    margin-left: 1rem;
                }
            }
        }
    }
}
@media screen and (max-width: 1130px) {
    #settings {
        .section-header {
            flex-direction: column;
            .btn-grouping {
                margin-top: 1rem;
                justify-content: space-between;
                flex-wrap: wrap;
                > button {
                    width: calc(50% - .5rem);
                    margin: 0;
                    max-width: 100%;
                    &:first-child {
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 1rem;
                        justify-content: center;
                        margin-left: 0;
                    }
                    &.btn-primary {
                        margin: 1rem auto 0 auto;
                    }
                }
            }
        }
        .section-sub-header {
            & > .location-field {
                padding: 10px 32px 10px 24px;
                height: 50px;
                svg {
                    width: 32px;
                    height: 32px;
                }
                span, input {
                    font-size: 16px;
                }
            }
        }
        .section-body {
            flex-direction: column;
            margin-top: 2rem;
            .open-hours-container {
                flex-wrap: wrap;
                justify-content: center;
                .open-card {
                    margin: 1rem 0 !important;
                    width: 100%;
                }
            }
            > div {
                width: 100%;
                &.info-box {
                    margin-top: 0;
                }
                h2 {
                    font-size: 22px;
                    min-height: initial;
                }
                input.disabled, span {
                    font-size: 18px;
                    margin-top: 0;
                }
                p {
                    font-size: 18px;
                }
                input, textarea {
                    width: 100%;
                    margin-top: 1rem;
                    font-size: 18px;
                }
                .owner {
                    .flex-box {
                        flex-direction: column;
                    }
                }
                &.social-media-box .flex-column > div {
                    margin-top: 1rem;
                    > .flex-box {
                        p {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1025px) and (orientation: landscape) {
    #settings {
        .section-body {
            > div {
                input {
                    width: 100%;
                }
            }
        }
    }
}