.tags {
    display: flex;
    align-items: center;
    padding: 0;
    list-style: none;
    width: 100%;
    flex-wrap: wrap;
    li {
        padding: 0.25rem .5rem;
        border-radius: 15px;
        color: $font-color-light;
        background-color: rgba(256,256,256, 0.25);
        cursor: pointer;
        margin-right: .5rem;
        margin-top: .5rem;
        transition: background-color 0.5s ease;
        &:hover {
            background-color: rgba(256,256,256, 0.15);
        }
        &.active {
            background-color: $font-color;
            color: $background-color;
        }
    }
}
.posts-box {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 3rem;
    
    .post-block {
        cursor: pointer;
        min-width: 320px;
        width: calc(100% / 3 - 1.5rem);
        background-color: $post-block-bg-color;
        margin-top: 1.5rem;
        box-shadow: 0 2px 20px -10px black;

        &:not(:nth-child(3n+1)) {
            margin-left: 1.5rem;
        }
        img {
            display: block;
            object-fit: cover;
            min-height: 280px;
            height: calc(100vw / 3 - 15rem - 13px);
            width: 100%;
        }
        .post-desc {
            padding: 32px;
            h3 {
                color: $font-color-light;
                font-size: 32px;
            }
            p {
                min-height: 80px;
            }
            .link-to-post {
                display: flex;
                align-items: center;
                color: $font-color-light;
                width: fit-content;
                transition: all 0.5s ease;
                svg {
                    margin-left: .5rem;
                }
                &:hover {
                    opacity: 0.5;
                }
            }
            .post-tags {
                list-style: none;
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                align-items: center;
                padding-top: 1rem;
                border-top: 1px solid $font-color;
                padding: .5rem 0;
                li {
                    padding: 0.25rem .5rem;
                    margin-top: 0.5rem;
                    margin-right: 0.5rem;
                    border-radius: 15px;
                    color: $font-color-light;
                    background-color: rgba(256,256,256, 0.25);
                }
            }
        }
    }
}
#news {
    min-height: 100vh;
    .post-desc .post-date {
        margin-top: .5rem;
        display: block;
    }
    h3 {
        margin: 1rem 0;
    }
}
#post {
    min-height: 100vh;
    max-width: 1200px;
    margin: auto;
    .post-title {
        border-bottom: 0;
    }
    .post-description {
        margin: 0;
        border: 0;
        a {
            border-bottom: 3px solid $font-color;
            &:hover {
                opacity: 0.5;
            }
        }
    }
    .flex-box {
        margin-top: 2rem;
    }
    .image-box {
        border: 0;
        overflow: hidden;
        img {
            opacity: 1;
            height: 100%;
        }
    }
    .post-date {
        color: rgba(256,256,256, 0.25);
        position: relative;
        bottom: -3rem;
    }
    .post-tags {
        list-style: none;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 1rem;
        border-top: 1px solid $font-color;
        padding: .5rem 0;
        li {
            padding: 0.25rem .5rem;
            border-radius: 15px;
            color: $font-color-light;
            background-color: rgba(256,256,256, 0.25);
            &:not(:first-child) {
                margin-left: .5rem;
            }
        }
    }
    .section-body.editable {
        input.post-title {
            border-bottom: 1px solid $font-color;
        }
        textarea {
            border: 1px solid $font-color;
        }
        .image-box {
            padding: 0;
            .uploader-info {
                position: absolute;
            }
            .image-preview {
                opacity: .25;
            }
        }
    }
}
@media screen and (max-width: 978px)  {
    .image-stats {
        display: none !important;
    }
    #news, #post {
        .section-header {
            flex-direction: column !important;
            margin-bottom: 1rem;
            align-items: center;
            > a {
                margin: 0 auto;
            }
            .btn-grouping {
                margin-top: 1rem;
                > button:first-child {
                    margin-left: 0;
                }
            }
            > svg {
                margin-right: auto;
            }
        }
        .posts-box .post-block .post-desc p {
            min-height: initial;
        }
        padding: 6rem 15px 15px 15px;
    }
    .posts-box {
        margin: 0 -15px;
        .post-block {
            min-width: 100%;
            width: 100%;
            margin-left: 0 !important;
        }
    }
    #new-post {
        .section-header {
            .btn-grouping {
                margin-top: 1rem;
                > button:first-child {
                    margin-left: 0;
                }
            }
            > svg {
                margin-right: auto;
            }
        }
        .section-body .image-box {
            margin-top: 1rem;
            min-width: 100% !important;
            left: 0;
        }
    }
    #new-post, #post {
        .section-header {
            flex-direction: column !important;
            margin-bottom: 1rem;
            align-items: center;
        }
        .btn-grouping {
            margin: 0 0 0 auto;
            min-width: initial;
        }
        .post-date {
            bottom: 0;
        }
        .post-title {
            margin: 0;
        }
        .btn-primary {
            margin-top: 0;
        }
        .flex-box {
            flex-direction: column !important;
        }
        .image-box {
            left: -15px;
            min-width: 100vw !important;
        }
        .image-box, .chooseFileButton, textarea, .post-description {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
        }
        .share-box {
            margin-top: 1rem;
        }
        .post-description, .post-description > a {
            margin-top: 2rem;
            line-height: 1.86;
            font-size: 18px;
            border-width: 1px !important;
            min-height: initial;
            height: auto;
            max-height: auto;
        }
        textarea {
            min-height: 200px;
            height: 200px;
            max-height: 200px;
        }
        button {
            transform: none !important;
            padding: 6px
        }
        textarea {
            margin-top: 1rem;
        }
    }
}
@media screen and (min-width: 976px) and (max-width: 1366px) {
    #post {
        .post-date {
            bottom: -2rem;
        }
    }
    #news {
        .posts-box {
            margin: 0;
            > .post-block {
                margin-bottom: 4rem;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
    #news {
        .posts-box {
            justify-content: space-between;
            margin: 0;
            .post-block {
                width: calc(50% - 15px);
                .post-desc p {
                    min-height: 105px;
                }
            }
        }
    }
}