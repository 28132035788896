header {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    position: fixed;
    left: 0;
    width: 100vw;
    top: 0;
    z-index: 10;
    background-color: transparent;
    transition: background-color 0.5s ease;
    &.with-background {
        background-color: $background-color-transparent;
        .logo {
            width: 3rem;
            height: 3rem;
        }
    }
    .logo {
        transition: all 0.5s ease;
        width: 6rem;
        height: 6rem;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
    }
    nav ul {
        list-style: none;
        display: flex;
        li {
            margin: 0 .5rem;
            cursor: pointer;
            &:hover,
            &:hover a {
                color: $font-color-light;
            }
            > a {
                transition: all 0.3s ease;
                position: relative;
                &::after {
                    content: '';
                    width: 0;
                    height: 3px;
                    background-color: $font-color-light;
                    position: absolute;
                    bottom: -5px;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: all 0.3s ease;
                }
                &.selected {
                    color: $font-color-light;
                    &::after {
                        width: 100%;
                    }
                }
            }
            p {
                margin: 0;
                line-height: 1.6;
            }
        }
    }
    .socials {
        position: relative;
        margin-left: auto;
        cursor: pointer;
        > .flex-box {
            font-size: 16px;
        }
        .socials-placeholder {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            left: 0;
            top: 0;
        }
        .popup {
            display: flex;
            position: absolute;
            right: 0;
            background-color: $background-color;
            padding: 16px 16px 24px 32px;
            min-width: 300px;
            transition: all 0.5s ease;
            transform: scale(0) translateY(-150px);
            opacity: 0;
            &.open {
                transform: scale(1) translateY(0);
                opacity: 1;
                box-shadow: 0px 6px 14px 1px #0707077a;
            }
            > .flex-box:first-of-type {
                margin-right: 3rem;
                > p {
                    font-size: 18px;
                }
            }
            > .flex-box {
                > p {
                    margin-bottom: 0;
                    margin-top: 10px;
                }
            }
        }
    }
    .social-links {
        display: flex;
        align-items: center;
        > div {
            &.social-icon {
                position: relative;
                &:after {
                    content: attr(data-text);
                    font-size: 12px;
                    color: $font-color;
                    background-color: $check-bg-off;
                    padding: 5px 15px;
                    position: absolute;
                    width: 120px;
                    text-align: center;
                    left: -50%;
                    opacity: 0;
                    transform: translate(-50%, 90%);
                    transition: all 0.5s ease;
                    z-index: 0;
                }
                &:hover:after {
                    opacity: 1;
                    transform: translate(-60%, 90%);
                }
                &:first-of-type {
                    svg {
                        margin-left: 0;
                    }
                }
            }
            > svg, > img {
                margin-right: .5rem;
                cursor: pointer;
                height: 2rem;
                width: 2rem;
                transition: all 0.5s !important;
                position: relative;
                z-index: 1;
                &:hover {
                    fill: $font-color-light;
                    transform: scale(1.2) !important;
                }
            }
            > img:hover {
                filter: brightness(2)
            }
            .google-icon {
                padding: 3px;
            }
        }
    }
}
@keyframes fromLeft {
    0% {
        transform: translateX(-100vw);
    } 100% {
        transform: translateX(0);
    }
}
@media screen and (max-width: 978px) {
    header.mobile {
        padding: 15px;
        ul {
            display: flex;
            flex-direction: column;
            height: 100vh;
            width: 100vw;
            padding: 0;
            position: fixed;
            left: 0;
            bottom: -100vh;
            background-color: $background-color;
            margin: 0;
            align-items: flex-start;
            justify-content: center;
            transition: all 0.5s ease;
            li {
                margin-bottom: 1.5rem;
                > a, p {
                    line-height: 1;
                }
                &:not(.socials) {
                    padding-left: 1rem;
                }
            }
        }
        &.open ul {
            bottom: 0;
        }
        .socials {
            width: 100%;
            .popup {
                position: fixed;
                transform: translateY(-100%) translateX(50%);
                right: 50%;
            }
            .popup.open {
                transform: translateY(5%) translateX(50%);
            }
            & > div:not(.popup) {
                justify-content: center;
            }
        }
        .logo {
            width: 52px;
            height: 52px;
            z-index: 2;
        }
        .btn-secondary {
            padding: 8px 15px;
            margin-left: auto;
            z-index: 2;
            border: 1px solid $font-color-light;
            color: $font-color-light !important;
            &:hover {
                background-color: transparent;
                color: $font-color !important;
            }
        }
    }
}
@media screen and (min-width: 978px) and (max-width: 978px) {
    nav.navigation > ul {
        margin: 0;
        > li {
            &:not(:first-child):not(.socials) {
                margin-top: 2rem;
            }
            > a, > p {
                font-size: 35px;
            }
            &.socials {
                svg, img {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
}
@media screen and (min-width: 978px) and (max-width: 1366px) {
    header {
        svg.logo {
            width: 70px;
            height: 70px;
        }
        nav.navigation > ul {
            margin: 0;
            > li {
                > a, > p {
                    font-size: 16px;
                }
                &.socials {
                    svg, img {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }
}