.section-widget {
    position: relative;
    margin: 15rem 0;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    &:nth-child(even) {
        align-items: flex-end;
        .section-image {
            right: auto;
            left: -15vw;
        }
    }
    .section-info {
        position: relative;
        z-index: 1;
        background-color: $font-color;
        max-width: calc(70vw - 20rem);
        min-width: 530px;
        padding: 3rem;
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 60px;
        .section-subtitle {
            color: $background-color;
            margin-top: 0;
            margin-bottom: 0;
            font-weight: bold;
        }
    }
    .section-title {
        width: fit-content;
        color: $font-color-light;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 900;
        position: relative;
        z-index: 1;
    }
    .section-description {
        max-width: calc(50vw - 4rem);
        min-width: 320px;
        width: 100%;
        display: block;
        margin: 2rem 0;
        letter-spacing: 1px;
        color: $description-color;
    }
    .section-image {
        width: 90vw;
        max-height: 90%;
        object-fit: cover;
        display: block;
        position: absolute;
        top: 40px;
        right: -15vw;
        // transform: translateY(-50%);
        z-index: 0;
    }
}
@media screen and (max-width: 976px) {
    .section-widget {
        align-items: flex-start !important;
        margin-top: 8rem;
        margin-bottom: 2rem;
        .section-info {
            max-width: 100%;
            min-width: 100%;
            padding: 30px;
            top: 0;
        }
        .section-image {
            left: -15px !important;
            right: auto;
            top: 1rem;
            width: 100vw;
        }
        .section-title {
            margin-top: -.65rem;
        }
        .section-description {
            min-width: 100%;
            max-width: 100%;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    section {
        .section-description {
            width: 100% !important;
            min-width: 100% !important;
            max-width: 100% !important;
        }
        .section-info {
            max-width: 80% !important;
            min-width: 80% !important;
            padding: 48px;
            top: 0 !important;
        }
        &#principles {
            .section-title {
                font-size: 56px;
            }
        }
    }
}
@media screen and (min-width: 976px) and (max-width: 1366px) {
    .section-widget {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}