.tag-input {
    input {
        width: 100%;
    }
    .tags-box {
        display: flex;
        margin-bottom: .5rem;
        > span {
            padding: .2rem .5rem;
            border-radius: 15px;
            background-color: $font-color;
            color: $background-color;
            display: flex;
            align-items: center;
            &:not(:first-child) {
                margin-left: .5rem;
            }
            .close-icon {
                fill: $background-color;
                margin-left: .25rem;
            }
        }
    }
}