@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;700;900&display=swap');

@import './colors';
@import './sizes';
@import './randomize';
@import './animations';
@import './regular/header';
@import './regular/section';
@import './regular/posts';
@import './regular/home';
@import './regular/footer';
@import './regular/gallery';
@import './regular/team';
@import './regular/settings';
@import './regular/contact';
@import './widgets/forms';
@import './widgets/buttons';
@import './widgets/inputs';
@import './widgets/tag';
@import './widgets/sections';
@import './widgets/abilities';
@import './widgets/modal';
@import './widgets/checkbox';
@import './widgets/dropdown';
@import './widgets/daypicker';

* {
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $check-bg-off;
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $check-bg-off;
    }
}
body {
    background-color: $background-color;
    overflow-x: hidden;
    overflow-y: auto;
    margin: auto;
    > iframe {
        display: none;
    }
    * {
        color: $font-color;
        font-family: 'Poppins', sans-serif;
    }
    &.modal-open {
        overflow-y: hidden;
    }
    #law-privacy,
    #law-impressum {
        a {
            border-bottom: 1px solid $font-color;
        }
        h3 {
            color: $font-color-light;
        }
    }
    #otra-vida-webpage {
        margin: auto;
        padding: 0 4rem;
        overflow-x: hidden;
    }
    .flex-box {
        display: flex;
        &.reverse-box {
            flex-direction: row-reverse;
        }
        &.flex-column {
            flex-direction: column;
        }
        &.align-center {
            align-items: center;
        }
    }
    .share-box {
        display: flex;
        align-items: center;
        &:hover {
            opacity: 0.5;
        }
        svg {
            margin-right: .5rem;
        }
    }
    .loading-box {
        width: 100%;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    textarea {
        padding: 15px;
        background-color: transparent;
        min-width: 200px;
        min-height: 200px;
        resize: none;
        border: 1px solid $font-color;
        margin-bottom: 1rem;
    }
    .information-about-login {
        padding: 2rem;
        margin-top: 2rem;
        h2 {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 18px;
        }
    }
    .location-icon {
        fill: $error-color;
        transform: scale(1.5) !important;
    }
    .err-msg {
        color: white;
        background-color: $error-color;
        text-align: center;
        padding: .5rem 1rem;
    }
}
@media screen and (max-width: 1130px) {
    body #otra-vida-webpage {
        padding: 0;
        overflow-x: hidden;
        overflow-y: auto;
        .information-about-login {
            padding: 1rem;
            h2 {
                font-size: 1.5rem;
            }
        }
    }
}