
.modal {
    margin: auto;
    z-index: 13;
    padding: 1rem 2rem;
    background-color: $font-color-light;
    position: fixed;
    top: 50%;
    left: 50%;
    .modal-header {
        display: flex;
        align-items: flex-start;
        h3 {
            color: $background-color;
            margin: 0 4rem 0 0;
            font-size: 2rem;
        }
        svg {
            margin-left: auto;
            cursor: pointer;
            transition: all 0.5s ease !important;
            &:hover {
                transform: rotate(180deg) !important;
                fill: $background-color;
            }
        }
    }
    .modal-body {
        p,
        p > b {
            color: $description-color;
        }
        input {
            border-color: $background-color;
            color: $background-color;
            margin: 1rem 0;
            width: 100%;
        }
    }
    .modal-footer {
        display: flex;
        .btn-grouping {
            margin-left: auto;
            margin-top: 0;
        }
    }
}
.backdrop {
    position: fixed;
    z-index: 12;
    background-color: $background-color-transparent;
    width: 100vw;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
}

@media screen and (max-width: 978px) {
    .modal {
        width: calc(100% - 30px);
    }
}