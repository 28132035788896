#team {
    min-height: 100vh;
    .employee-widget {
        position: relative;
        margin: 240px 0 10rem 0;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem 0;
        &:first-child {
            margin: 80px 0 0 0;
        }
        .employee-name {
            color: $font-color-light;
            position: relative;
            z-index: 1;
            margin-bottom: 0;
            line-height: 1;
            margin-bottom: auto;
            margin-top: 0;
        }
        .employee-description {
            max-width: calc(40vw - 4rem);
            min-width: 320px;
            width: 100%;
            display: block;
            position: relative;
            z-index: 1;
            margin: 2rem 0;
            letter-spacing: 1px;
            max-height: 250px;
            min-height: 200px;
            height: 100%;
            overflow-y: auto;
        }
        .btn-secondary {
            margin-bottom: auto;
        }
        .employee-image {
            position: absolute;
            max-width: calc(70% - 4rem);
            height: 100%;
            min-height: 600px;
            width: 100%;
            object-fit: cover;
            display: block;
            top: 0;
            right: -15vw;
            z-index: 0;
        }
        &:nth-child(even) {
            align-items: flex-end;
            .employee-image {
                left: -15vw;
                right: auto;
            }
            .employee-description {
                text-align: right;
            }
            .btn-grouping > button:first-child {
                margin-left: auto;
            }
        }
        &.editable {
            &:nth-child(even) .reverse-box {
                flex-direction: initial;
                width: 100%;
            }
        }
        .btn-grouping {
            margin-top: 1rem;
        }
        button {
            transition: initial;
        }
    }
}
@media screen and (max-width: 1130px) {
    .uploader-info h3 {
        font-size: 20px !important;
    }
    #team {
        .section-header {
            flex-direction: column;
            align-items: baseline;
            > .btn-primary {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
        .employee-widget {
            margin: 1rem 0;
            padding: 1rem 0;
            align-items: center !important;
            max-height: initial;
            min-height: auto;
            height: auto;
            &:first-child {
                margin-top: 2rem;
            }
            .employee-name {
                position: relative;
                margin-bottom: 0;
                margin-left: 0;
                margin-right: auto;
                top: 1rem;
            }
            .employee-image {
                left: 0 !important;
                right: auto !important;
                min-width: calc(100% + 30px);
                height: 400px;
                min-height: 400px;
                max-height: 400px;
                top: -15px;
                position: relative;
            }
            .employee-description {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                margin-top: 0;
                text-align: left !important;
                min-height: initial;
            }
            .btn-grouping {
                margin-top: 0;
                padding: 0 15px;
            }
            &.editable {
                > .flex-box {
                    flex-direction: column !important;
                    width: 100%;
                    input {
                        margin: 1rem 0 0 0;
                    }
                }
                .image-box, .chooseFileButton, textarea {
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;
                }
                .image-box,
                textarea {
                    min-height: 200px;
                    height: 200px;
                    max-height: 200px;
                }
                button {
                    transform: none !important;
                }
                textarea {
                    margin-top: 1rem;
                }
            }
        }
    }
    #new-employee {
        .section-header {
            flex-direction: initial !important;
            margin-bottom: 1rem;
            align-items: center;
        }
        .btn-grouping {
            margin: 0 0 0 auto;
            width: auto;
            min-width: initial;
        }
        .post-title {
            margin: 0;
        }
        .btn-primary {
            margin-top: 0;
        }
        .flex-box {
            flex-direction: column !important;
        }
        .image-box, .chooseFileButton, textarea, .post-description {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
        }
        .post-description, .post-description > a {
            margin-top: 4rem;
            line-height: 1.5;
            border-width: 1px !important;
            min-height: 120px;
            height: auto;
            max-height: auto;
        }
        .image-box {
            .image-preview {
                max-height: 200px;
            }
            .image-stats {
                bottom: -2.5rem;
            }
        }
        textarea {
            min-height: 200px;
            height: 200px;
            max-height: 200px;
        }
        button {
            transform: none !important;
            padding: 6px
        }
        textarea {
            margin-top: 1rem;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #team {
        .employee-widget {
            padding: 3rem 6rem;
            .employee-name {
                width: 100%;
                > span {
                    margin-right: 1rem;
                }
                br {
                    display: none;
                }
            }
            .employee-image {
                min-height: 400px;
                height: initial;
                max-height: 400px;
                min-width: calc(100%);
            }
            .btn-grouping {
                padding: 0;
            }
        }
    }
}