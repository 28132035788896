footer {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    .top {
        border-bottom: 1px solid $font-color;
        display: flex;
        > div {
            // width: calc(100% / 3);
            padding: 2rem;
        }
        .address-box {
            display: flex;
            img {
                max-width: 100px;
                max-height: 100px;
                display: block;
                margin-right: 1rem;
            }
            .company-name {
                color: $font-color-light;
                margin-top: 0;
                margin-bottom: 1rem;
                font-weight: bold;
                // min-width: 165px;
            }
            .address {
                display: flex;
                flex-direction: column;
                p, b {
                    margin: 0;
                    font-size: 14px;
                }
            }
            .company-name {
                font-size: 19px;
                color: $font-color-light;
                line-height: normal;
            }
            .owner-name {
                font-size: 14px;
            }
        }
        .contact-box {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            .location-footer {
                color: $font-color-light;
                display: flex;
                align-items: center;
                font-size: 19px;
                font-weight: bold;
                > svg {
                    color: $font-color;
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                }
            }
            .contact {
                display: flex;
                align-items: center;
                margin-bottom: 0.25rem;
                font-size: 14px;
                padding-left: 40px;
                svg, img {
                    width: 16px;
                    height: 16px;
                    margin-right: .5rem;
                }
                p {
                    margin: 0;
                    font-size: 14px;
                    > a {
                        font-size: 14px;
                    }
                }
            }
        }
        .navigation-box {
            display: flex;
            flex-direction: column;
            > a {
                margin-bottom: 1rem;
                font-size: 14px;
                &:hover {
                    opacity: .5;
                }
            }
        }
        .locations-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
        }
    }
    .bottom {
        margin-top: 1rem;
        margin-bottom: 4rem;
        display: flex;
        align-items: center;
        > a {
            margin-right: 1rem;
            font-size: 14px;
            position: relative;
            &::after {
                content: '';
                width: 0;
                height: 3px;
                background-color: $font-color-light;
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                transition: all 0.3s ease;
            }
            &.selected {
                color: $font-color-light;
                &::after {
                    width: 100%;
                }
            }
            &:hover {
                opacity: .5;
            }
        }
        > img {
            margin-left: auto;
        }
        .creators {
            align-items: center;
            font-size: 14px;
            margin-left: auto;
            cursor: pointer;
            transition: opacity 0.5s ease;
            &:hover {
                opacity: 0.5;
            }
            > svg {
                margin: 0 .25rem;
                fill: $trippy-pixels-color;
            }
        }
    }
    .information-about-login {
        margin-top: 0 !important;
        padding: 1rem !important;
        height: 100%;
        h2 {
            line-height: 1.2;
        }
        p {
        }
    }
}
.sub-footer {
    padding: 3rem;
    background-color: $font-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 80px 0 3rem 0;
    h2 {
        color: $background-color;
        text-align: center;
        margin-top: 0;
    }
}
.go-top {
    display: block;
    position: fixed;
    right: -64px;
    bottom: 2rem;
    width: 64px;
    height: 64px;
    padding: 10px;
    background-color: $font-color;
    transition: all 0.5s ease;
    cursor: pointer;
    &.show {
        right: 2rem;
    }
}
@media screen and (max-width: 1130px) {
    footer {
        .go-top {
            width: 40px;
            height: 40px;
            padding: 5px;
            bottom: 15px;
            &.show {
                right: 15px;
            }
        }
        .top {
            flex-direction: column;
            > div {
                width: 100%;
                padding: 15px;
            }
            .address-box {
                align-items: center;
                img {
                    width: 80px;
                    height: 80px;
                }
                .info {
                    margin-top: 1rem;
                }
                .company-name {
                    margin-bottom: 0;
                }
            }
            .contact-box .contact {
                margin-bottom: 0;
                padding: .5rem 0;
            }
            .locations-container {
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                .contact {
                    padding-left: 2.5rem;
                }
            }
            .navigation-box {
                padding-left: 3.4rem;
            }
        }
        .bottom {
            padding: 0 15px;
            margin-bottom: 1rem;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            a {
                margin-right: 0;
                margin-bottom: .5rem;
                font-size: 12px;
            }
            .creators {
                margin-left: 0;
                font-size: 12px;
            }
        }
    }
    .sub-footer {
        padding: 2rem 15px !important;
        h2 {
            margin-bottom: 2rem;
        }
    }
}
@media screen and (min-width: 976px) and (max-width: 1366px) {
    footer {
        .top {
            > div {
                padding: 2rem 15px;
                &.address-box img {
                    max-width: 75px;
                    max-height: 75px;
                }
            }
        }
    }
}