.checkbox {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:after {
        content: '';
        display: block;
        height: 15px;
        width: 40px;
        background-color: $check-bg-off;
        border-radius: 10px;
        transition: all 0.5s ease;
    }
    span {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: $font-color-light;
        border-radius: 50%;
        transition: all 0.5s ease;
        left: 0;
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    &.checked {
        &:after {
            background-color: $check-bg-on;
        }
        span {
            left: 20px;
            // right: 0;
        }
    }
}