.days-cards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    > div {
        border: 1px solid $font-color;
        padding: 40px;
        width: calc(100% / 3 - 1.67rem);
        min-width: 325px;
        position: relative;
        margin-left: 1rem;
        margin: 1rem;
        &:nth-child(3n-2) {
            margin-left: 0;
        }
        .btn-drop {
            margin-top: 0;
        }
        .icon-btn {
            position: absolute;
            top: 15px;
            right: 15px;
        }
        > .flex-box {
            margin-top: 1rem;
            justify-content: initial;
            align-items: center;
            > p {
                margin: 0 0 0 .5rem;
            }
        }
        .react-time-picker__clock {
            display: none;
        }
        .react-time-picker__wrapper {
            border: 1px solid $font-color;
            padding: .5rem 1rem;
        }
        .react-time-picker__button__icon {
            stroke: $font-color;
        }
        .all-day-time,
        .separate-day-time {
            .flex-box {
                margin-bottom: 0;
            }
            .from-to-box {
                flex-wrap: wrap;
                > div {
                    width: 100%;
                    p {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1325px) {
    .days-cards {
        > div {
            width: calc(100% / 2 - 1.67rem);
            &:nth-child(2n - 1) {
                margin-left: 0;
            }
        }
    }
}
@media screen and (max-width: 1130px) {
    .days-cards  {
        flex-wrap: wrap;
        > div {
            width: 100%;
            margin: 1rem 0 !important;
            input {
                margin-top: 0 !important;
            }
        }
    }
}