$standard-width: 1384px;
h1, .post-title {
    font-size: 60px;
    line-height: 1.2;
}
h3, .sub-footer > h2 {
    font-size: 46px;
    line-height: 1.2;
}
h2, .section-title {
    font-size: 120px;
    line-height: 1.2;
}
h4 {
    font-size: 24px;
}
p {
    font-size: 18px;
    line-height: 1.8;
}
a, button, input, textarea {
    font-size: 18px;
}
.navigation {
    a, p {
        font-size: 18px;
    }
}
@media screen and (max-width: 976px) {
    h1, .post-title {
        font-size: 30px;
        line-height: 1.75;
    }
    h3, .sub-footer > h2 {
        font-size: 20px;
        line-height: 1.5;
    }
    h2, .section-title {
        font-size: 46px;
        line-height: 1.5 !important;
    }
    p {
        font-size: 14px;
        line-height: 1.75;
    }
    a, input, textarea {
        font-size: 16px;
    }
    [class*="btn-"] {
        font-size: 14px;
    }
    .navigation {
        a, p {
            font-size: 26px;
        }
    }
    li > a {
        font-size: 14px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    h1, .post-title {
        font-size: 56px;
        line-height: 1.2;
    }
    h2, .section-title {
        font-size: 96px;
        line-height: 1.2 !important;
    }
    h3, .sub-footer > h2 {
        font-size: 32px;
    }
    h4 {
        font-size: 24px;
    }
    p {
        font-size: 18px;
        line-height: 1.86;
    }
    a, button, input, textarea {
        font-size: 18px;
    }
    .navigation {
        a, p {
            font-size: 22px;
        }
    }
}

@media screen and (min-width: 976px) and (max-width: 1367px) {
    h1, .post-title {
        font-size: 56px;
    }
    h2, .section-title {
        font-size: 96px;
    }
    h3, .sub-footer > h2 {
        font-size: 32px;
    }
    h4 {
        font-size: 24px;
    }
    p {
        font-size: 18px;
        line-height: 1.86;
    }
    a, button, input, textarea {
        font-size: 18px;
    }
    .navigation {
        a, p {
            font-size: 22px;
        }
    }
}