* {
    box-sizing: border-box;
}
body {
    padding: 0;
    margin: 0;
}
h1 {
    color: $font-color;
    margin: 0;
}
section {
    padding: 3rem 2rem 0 2rem;
}
a {
    text-decoration: none;
    color: $font-color;
    cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 1366px) {
    section, header, footer, .sub-footer {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}