.section-header {
    display: flex;
    align-items: center;
    .btn-primary {
        margin-left: auto;
    }
    .btn-grouping {
        margin-left: auto;
        margin-top: 0;
        > button:first-child {
            margin-left: auto;
        }
    }
}
.section-sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;
    padding-bottom: 2rem;
}
.section-container {
    // position: relative;
    display: flex;
    // flex-direction: row;
    // min-height: 1780px;
}
.section-body {
    .post-title {
        border: 0;
        border-bottom: 1px solid $font-color;
        margin: 3rem 0;
        width: 100%;
    }
    input.post-title,
    textarea {
        transition: all 0.3s ease;
        &:hover {
            background-color: $light-white-transparent;
        }
    }
    .flex-box {
        justify-content: space-between;
        margin-bottom: 2rem;
    }
    .image-box, .post-description {
        min-width: 400px;
        min-height: 400px;
        max-width: 800px;
        max-height: 800px;
        width: calc(50% - 2rem);
        height: calc(50% - 2rem);
        display: block;
        border: 1px solid $font-color;
        background-color: transparent;
        resize: none;
    }
    .input-box {
        padding: .5rem 1rem;
        border: 1px solid $font-color;
        height: 64px;
    }
    label {
        p {
            margin-bottom: .5rem;
        }
    }
    .image-box {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .fileUploader {
            width: initial;
        }
        .fileContainer {
            background-color: transparent;
            box-shadow: none;
            position: inherit;
            border-radius: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 0;
            p {
                display: none;
            }
            .errorsContainer {
                display: none;
            }
            .chooseFileButton  {
                margin: 0;
                background-color: transparent;
                position: absolute;
                width: 100%;
                height: 100%;
                bottom: 0;
                border-radius: 0;
                z-index: 2;
                &:hover {
                    background-color: rgba(256,256,256, 0.1);
                }
            }
        }
        .uploader-info {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            h3 {
                margin: 1rem 0;
                font-size: 26px;
            }
            p {
                margin: 0;
                opacity: 0.25;
            }
            svg {
                width: 4rem;
                height: 4rem;
            }
        }
        .image-preview {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            opacity: 0.35;
            object-fit: contain;
        }
        .image-stats {
            display: flex;
            position: absolute;
            bottom: 1rem;
            background-color: $background-color-transparent;
            padding: 5px 10px;
            p {
                margin: 0;
                &:not(:first-child) {
                    margin-left: .5rem;
                }
            }
        }
    }
    opacity: 1;
    transition: all 0.5s ease;
    min-width: 100%;
    &:not(:first-child) {
        margin-left: 2rem;
    }

}
section {
    padding-top: calc(128px + 2rem);
}
@media screen and (max-width: 978px) {
    .section-body .homepage-box > .flex-box {
        flex-direction: column;
        .image-box {
            margin-left: 0 !important;
            margin-top: 2rem;
            min-width: 100%;
            max-width: 100%;
            aspect-ratio: 1/1;
            min-height: initial;
        }
    }
}
@media screen and (max-width: 1130px) {
    section {
        padding: calc(80px + 2rem) 15px 0 15px;
        .uploader-info {
            h3 {
                text-align: center;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1025px) and (orientation: portrait) {
    section {
        padding: calc(80px + 4rem) 15px 0 15px;
    }
}