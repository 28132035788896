
.abilities-box {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 5rem;
    .ability {
        width: 50%;
        display: flex;
        padding: 2rem;
        img {
            max-width: 65px;
            width: 100%;
            max-height: 65px;
            display: block;
            margin-right: 1rem;
        }
        .ability-info {
            display: flex;
            flex-direction: column;
            .ability-title {
                margin-top: 0;
                margin-bottom: 1rem;
                color: white;
                font-weight: bold;
            }
            .ability-description {
                margin-top: 0;
            }
        }
    }
}
@media screen and (max-width: 1130px) {
    .abilities-box {
        flex-direction: column;
        margin-top: 4rem;
        .ability {
            width: 100%;
            padding: 0;
            margin-bottom: 2.5rem;
            flex-direction: column;
            .ability-title {
                line-height: 1.2;
            }
            img {
                margin-bottom: 1.5rem;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1025px) and (orientation: landscape) {
    .abilities-box {
        .ability {
            .ability-info {
                .ability-title {
                    line-height: 1.2;
                }
            }
        }
    }
}