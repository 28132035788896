form {
    display: flex;
    flex-direction: column;
    max-width: 696px;
    width: 100%;
    margin-top: 80px;

    label {
        margin-top: 1rem;
        input {
            width: 100%;
        }
        &.require {
            p {
                &::after {
                    content: '*';
                    margin-left: 5px;
                }
            }
        }
        p {
            margin: 0;
        }
    }
    button {
        margin-top: 32px;
    }
}