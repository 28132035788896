@keyframes rotateIcon {
    0% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes heartBeat {
    0% {
        transform: scale( .75 );
    }
    20% {
        transform: scale( 1 );
    }
    40% {
        transform: scale( .75 );
    }
    60% {
        transform: scale( 1 );
    }
    80% {
        transform: scale( .75 );
    }
    100% {
        transform: scale( .75 );
    }
}
.compression-icon {
    animation-name: rotateIcon;
    animation-delay: 0.3;
    animation-timing-function: cubic-bezier(.58,0,.42,1);
    animation-duration: 2s;
    animation-direction: normal;
    animation-iteration-count: infinite;
}
.logo-anim {
    animation-name: rotateIcon;
    animation-delay: 0.3;
    animation-timing-function: cubic-bezier(.58,0,.42,1);
    animation-duration: 2s;
    animation-direction: normal;
    animation-iteration-count: infinite;
}
.heart-beat-anim {
    animation-name: heartBeat;
    animation-delay: 0.3;
    animation-timing-function: cubic-bezier(.58,0,.42,1);
    animation-duration: 2s;
    animation-direction: normal;
    animation-iteration-count: infinite;
}