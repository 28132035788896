@mixin btn-standards($bg-color, $bg-hover-color, $font-color, $font-hover-color, $border-color) {
    background-color: $bg-color;
    text-align: center;
    padding: .5rem 1.5rem;
    color: $font-color !important;
    cursor: pointer;
    border: 1px solid $border-color;
    font-weight: bold;
    max-width: fit-content;
    text-decoration: none !important;
    transition: all 0.3s ease;
    &:hover {
        background-color: $bg-hover-color;
        color: $font-hover-color !important;
    }
    &.with-icon {
        display: flex;
        align-items: center;
        svg {
            margin-left: .25rem;
        }
    }
    &.disabled,
    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}

.btn-primary {
    @include btn-standards($font-color, $font-color-light, $background-color, $background-color, transparent);
}
.btn-primary-dark {
    @include btn-standards($background-color, $description-color, $font-color, $font-color-light, transparent);
}
.btn-secondary {
    @include btn-standards(transparent, $font-color-light, $font-color-light, $background-color, $font-color-light);
    &:hover {
        border-color: $background-color;
    }
}
.btn-secondary-dark {
    @include btn-standards(transparent, $background-color, $background-color, $font-color, $background-color);
}
.btn-warning {
    @include btn-standards($error-color, $error-color-hover, $font-color-light, $font-color-light, transparent)
}
.btn-back {
    background-color: rgba(256, 256, 256, 0.25);
    fill: $font-color;
    cursor: pointer;
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 50%;
    transition: all 0.3s ease !important;
    &:hover {
        background-color: $font-color;
        fill: $background-color;
    }

}

.btn-grouping {
    display: flex;
    margin-top: 2rem;
    min-width: 320px;
    width: 100%;
    > [class*="btn-"]:not(:first-child) {
        margin-left: .5rem;
    }
}

.icon-btn {
    cursor: pointer;
    margin-left: auto;
    &:hover {
        fill: white;
    }
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}